import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";

const OptinPage = ({ location }) => {
  let headline = "";
  let message = "";
  // prevent flashing of error message as default if its success
  // so we load empty strings by default
  if (location.search) {
    const isSuccess =
      location.search && location.search.includes("success=true");
    headline = isSuccess ? "Erfolg" : "Fehler";
    message = isSuccess
      ? "Deine E-Mail-Adresse wurde erfolgreich bestätigt."
      : "Leider gab es einen Fehler bei der Bestätigung deiner E-Mail-Adresse. Probier es noch einmal oder kontaktiere uns.";
  }

  return (
    <Layout>
      <SEO title="Newsletter Optin" />
      <Intro
        image={require("../images/neu/wassermelone.jpg")}
        headline={headline}
      >
        <p>{message}</p>
      </Intro>
    </Layout>
  );
};

export default OptinPage;
